import React, {useEffect, useMemo, useState} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import DefaultLayout from './DefaultLayout';
import HomePage from "../../Pages/HomePage";
import HireServicesPage from "../../Pages/HireServicesPage";
import ServicePage from "../../Pages/ServicePage";
import {PrivateRoute} from "../../Helpers/PrivateRoute";
import useAuth from "../../Hooks/Auth";
import WorkHomePage from "../../Pages/WorkHomePage";
import AboutUsPage from "../../Pages/AboutUsPage";
import HelpPage from "../../Pages/HelpPage";
import {useDispatch, useSelector} from "react-redux";
import useLocalStorage from "../../Hooks/Storage";
import useApi from "../../Hooks/Api";
import {Button, notification} from "antd";
import PrivacyPage from "../../Pages/PrivacyPage";
import PasswordRecovery from "../../Pages/PasswordRecovery";
import SearchResumePage from "../../Pages/SearchResumePage";
import EstablishmentPage from "../../Pages/EstablishmentPage";
import SearchEstablishmentPage from "../../Pages/SearchEstablishmentPage";
import HowItWorksPage from "../../Pages/HowItWorksPage";
import useVerifyServices from "../../Hooks/VerifyServices";
import FullResumePage from "../../Pages/FullResumePage";
import {Images} from "../../Helpers/Images";
import ClientPanelPage from "../../Pages/ClientPanelPage";
import MyRecruitmentsPage from "../PageComponents/Restrict/ClientPanelPage/MyRecruitmentsPage";
import NotificationPage from "../PageComponents/Restrict/ClientPanelPage/NotificationPage";
import WorkerPanelPage from "../../Pages/WorkerPanelPage";
import {Env} from "../../Helpers/Env";
import PanelPage from "../../Pages/PanelPage";
import {StorageVariables} from "../../Helpers/StoragesVariables";
import LoginPageOld from "../../Pages/LoginPage";
import * as Sentry from "@sentry/react";
import Modal from "antd/es/modal";
import Icon from "antd/es/icon";
import {SearchOpportunityPage} from "../../Pages/SearchOpportunityPage";
import {SearchCoursesPage} from "../../Pages/SearchCoursesPage";
import ExternalAccessLayoutManager from "../../Pages/ExternallyAccess/ExternalAccessLayoutManager";
import CommonQuestions from "../../Pages/CommonQuestions";

const LayoutManager = props => {
    const {isLogged, getUser, store} = useAuth();
    const dispatch = useDispatch();
    let messaging = window.firebase?.messaging?.isSupported()? window.firebase.messaging() : null;
    const profile = useSelector(state => state).authReducer;
    const request = useSelector(state => state).pushNotificationRequestReducer;
    const {hasSineService, hasCraftyService, hasClubService} = useVerifyServices();
    const userCookiesPermission = useLocalStorage(StorageVariables.cookies);
    const [, setButtonCookieClicked] = useState(false);
    const [showBanner, setShowBanner] = useState(true);
    const fcmStorage = useLocalStorage(StorageVariables.fcm);
    const api = useApi();

    const configNotifications = () => {
        Notification.requestPermission().then((permission ) => {
            if (permission === 'granted') {
                messaging?.getToken().then(async (currentToken) => {
                    if (currentToken) {
                        if(fcmStorage.getPrimitive() !== currentToken) {
                            api.post('notifications/register', {fcm_token: currentToken, application_name: Env.application_name}, (res) => {}, (e) => {})
                        }
                        fcmStorage.setPrimitive(currentToken);
                        messaging?.onMessage((payload) => {
                            console.log(payload);
                            if(payload.data["crafty.fcm_type"] === "chat") {
                                dispatch({type: 'new_chat_notification', data: {chat_id: payload.data["crafty.chat_id"]}});
                            } else {
                                dispatch({type: 'add_notification'});
                                const key = 'notificationGlobalLocalKey';
                                notification.open({
                                    duration: 7,
                                    description: <div style={{display: 'flex', marginTop: -20}}>
                                        <div style={{paddingRight: 5}}> <img style={{height: 40}} src={Images.logo_icon}/> </div>
                                        <div style={{display: 'flex', flexFlow: 'column'}}>
                                            <strong style={{fontSize: 16}}> Notificação </strong>
                                            <span> {payload.notification?.body || 'Você recebeu uma nova notificação'} </span>
                                        </div>
                                    </div>,
                                    key,
                                    placement: 'topRight'
                                });
                            }
                        });
                        dispatch({type: 'enabled_push', data: true})
                    } else {
                        console.log('No Instance ID token available. Request permission to generate one.');
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
            } else {
                console.log('Unable to get permission to notify.');
            }
        });
    };

    const getNotifications = async () => {
        api.get('notifications/count', (res) => {
            dispatch({type: 'init_count', data: res.count});
        }, (e) => {
            console.log(e);
        });
    };


    const handleCookiesAccept  = () => {
        userCookiesPermission.setPrimitive(true);
        setButtonCookieClicked(true);
    };


    useMemo(() => {
        const userObj = getUser();

        if(userObj?.token) {
            store(userObj);
            getNotifications();
        }
        if (messaging !== null) {
            try {
                messaging?.usePublicVapidKey("BJfOZ_N3UFBHd3bWbkJyl-FDN1_EytfMm5k-Ham37qNzG4ML2f9A3JLCqPN1Vx1qfda3jqJkL9dCtp2BhIa_gWM");
            } catch (e) {
                console.log(e);
            }
        }
    }, []);

    useEffect(() => {
        if(profile !== null && messaging !== null) {
            configNotifications();
        }
    },[profile, request]);

    useEffect(() => {
        Sentry.addBreadcrumb({
            category: "active_screen",
            message: window.location.pathname,
            level: Sentry.Severity.Info,
        });
        window.gtag&& window.gtag('config', 'G-9RQB713QZ1', {'page_path': window.location.pathname});
    },[window.location.pathname]);

    return (
        <Switch>
            <Route>
                <DefaultLayout>
                    <Route exact path={'/'} component={isLogged()? PanelPage : HomePage}/>
                    <PrivateRoute exact path={'/painel'} component={PanelPage}/>
                    <PrivateRoute path={'/painel/cliente'} component={ClientPanelPage}/>

                    <Route exact path={'/como-funciona/:type'} component={HowItWorksPage}/>
                    <Route exact path={'/termos'} component={PrivacyPage}/>
                    <Route exact path={'/politica-de-privacidade'} component={PrivacyPage}/>
                    <Route exact path={'/redefinir-senha/:token'} component={PasswordRecovery}/>
                    <Route exact path={'/trabalhar'} component={isLogged()? PanelPage : WorkHomePage}/>
                    <Route exact path={'/recuperar-senha'} component={LoginPageOld}/>



                    {hasCraftyService() && <Route exact path={'/quero-contratar'} component={HireServicesPage}/>}
                    {hasCraftyService() && <Route exact path={'/prestador-de-servico/:id/:occupation'} component={ServicePage}/>}

                    {hasClubService() && <Route exact path={'/estabelecimentos'} component={SearchEstablishmentPage}/>}
                    {hasClubService() && <Route exact path={'/estabelecimento/:id'} component={EstablishmentPage}/>}
                    {hasSineService() && <PrivateRoute exact path={'/recrutamentos/:type'} component={MyRecruitmentsPage}/>}
                    <PrivateRoute exact path={'/notificacoes'} component={NotificationPage}/>

                    <Route path={'/perguntas-frequentes'} component={CommonQuestions}/>
                    {/*<Redirect to={'/'}/>*/}
                </DefaultLayout>
            </Route>

            {!userCookiesPermission.getPrimitive() && <div className='fixedNotifications'>
                <div className={'cookiesMessage'}>
                    <span style={{marginRight: '10px', paddingTop: '7px',fontSize: '13px'}}>Nós utilizamos cookies para garantir que forneceremos a melhor experiência em nosso site.</span>
                    <div>
                        <Button style={{marginRight: 10}} type={'primary'} onClick={() => handleCookiesAccept()}> Aceitar </Button>
                        <Button className={'ant-btn-red'} href={'https://www.google.com.br'}> Eu Discordo </Button>
                    </div>
                </div>
            </div>}

        </Switch>
    )

};

LayoutManager.propTypes = {};

export default withRouter(LayoutManager);
